(function($){
    var flip = document.querySelectorAll('.flip-card');
    flip.forEach(element => element.setAttribute('draggable', 'true'));
    function playVideo() {
        console.log('playing')
        let logo = document.querySelector(".logo");
        let topSection = document.querySelector(".top-section");
        // let player = document.querySelector(".player");
        let videoSection = document.querySelector('.videos-section');
        let hideVideoButton = document.querySelector(".hide-video-button");
        let videoTag = document.querySelector(".video-tag");
        let header = document.getElementById('header');

        // player.disabled = true;
        // player.style.visibility = "hidden";
        header.style.visibility = 'hidden';
        videoSection.style.opacity = '0';
        topSection.classList.remove("overlay");
        logo.style.visibility = "hidden";
        videoTag.setAttribute("controls", "controls");
        videoTag.muted = false;
        videoTag.style.zIndex = 2;
        hideVideoButton.classList.add("show");
        videoTag.currentTime = 0;
        videoTag.play();
    }
    function hideVideo() {
        let logo = document.querySelector(".logo");
        let topSection = document.querySelector(".top-section");
        // let player = document.querySelector(".player");
        let videoSection = document.querySelector('.videos-section');
        let hideVideoButton = document.querySelector(".hide-video-button");
        let videoTag = document.querySelector(".video-tag");
        let header = document.getElementById('header');

        // player.disabled = false;
        header.style.visibility = 'visible';
        // player.style.visibility = "visible";
        videoSection.setAttribute('style', '');
        topSection.classList.add("overlay");
        logo.style.visibility = "visible";
        videoTag.removeAttribute("controls");
        videoTag.muted = true;
        videoTag.style.zIndex = 0;
        hideVideoButton.classList.remove("show");
    }
    
    /*
    function showHelpSuggestions(bool, reason) {
        let helpSuggest  = document.querySelector(".help-suggest");

        if (bool) {
            helpSuggest.classList.add("show");
        } else {
            helpSuggest.classList.remove("show");
        }
    }

    function enableHelpButton() {
        let helpName = document.querySelector(".help-name-div input");
        let helpMail = document.querySelector(".help-section input[type='email']");
        let helpLinkedin = document.querySelector(".help-section input[type='url']");
        let helpTextarea = document.querySelector(".help-section textarea");
        let helpButton = document.querySelector(".help-button");

        showHelpSuggestions(false, 'enableHelpButton');
        if (helpButton.disabled === true) {
            helpButton.disabled = false;
            helpButton.value = "Send to Aleph";
            helpMail.classList.remove("error");
            helpName.classList.remove("error");
            helpTextarea.classList.remove("error");
        }
    }
    */

    function enableNewsletterButton() {
        let newsletterButton = document.querySelector(".newsletter input[type='submit']");
        let newsletterInput = document.getElementById("newsletter-input");

        if (newsletterButton.disabled === true) {
            newsletterButton.disabled = false;
            newsletterButton.value = "Sign Up to Our Newsletter";
            newsletterInput.classList.remove("error");
        }
    }

    function toggleMenu(e) {
        if (e) {
            e.preventDefault();
        }
        let hamburger = document.querySelector(".menu-opener");
        let mainNavigation = document.querySelector(".main-navigation");
        let newsletter = document.querySelector(".newsletter");

        if (mainNavigation.classList.contains("menu")) {
            mainNavigation.classList.remove("menu");
            // document.body.style.overflow = "";
            hamburger.classList.remove("active");
        } else {
            mainNavigation.classList.add("menu");
            // document.body.style.overflow = "hidden";
            hamburger.classList.add("active");
            newsletter.classList.remove("sticky", "unsticky");
        }
    }

    let prevScroll = 0;
    let scrollDown = true;

    function scrollFunc() {
        // console.log("scroll");

        let headerSocial = document.querySelector("header .social-networks");
        let newsletter = document.querySelector(".newsletter");

        if (newsletter.classList.contains("menu") === false) {
            // let nowScroll = document.documentElement.scrollTop;
            // let nowScroll = jQuery( document.body ).scrollTop();
            let nowScroll = $(window).scrollTop();
            // console.log(nowScroll);
            if (prevScroll < nowScroll) {
                if (nowScroll > 300) {
                    if (window.innerWidth > 1250) {
                        newsletter.classList.add("sticky");
                        headerSocial.classList.add("no-news");
                    }
                } else {
                    if (scrollDown === false) {
                        scrollDown = true;
                    }
                }
            } else {
                if (nowScroll < 300) {
                    if (scrollDown === false) {
                        newsletter.classList.add("unsticky");
                        setTimeout(function () {
                            newsletter.classList.remove("sticky");
                            newsletter.classList.remove("unsticky");
                            headerSocial.classList.remove("no-news");
                            newsletter.classList.add("appear");
                            setTimeout(function () {
                                newsletter.classList.remove("appear");
                            }, 400);
                        }, 400);
                    }
                } else {
                    if (scrollDown) {
                        scrollDown = false;
                    }
                }
            }
            prevScroll = nowScroll;
        }
    }

    function scrollTo(element) {
        // element.scrollIntoView({ block: 'start',  behavior: 'smooth' });

        $('html, body').animate({
            scrollTop: $(element).offset().top
        }, 500);
    }

    function scrollToId(id) {
        let mainNavigation = document.querySelector(".main-navigation");

        let element = document.getElementById(id);
        if (mainNavigation.classList.contains("menu")) {
            toggleMenu();
            setTimeout(() => {
                scrollTo(element);
            }, 100);
        } else {
            scrollTo(element);
        }
    }

    function bindUlHrefs(ul) {
        let links = ul.children;
        for (let i = 0; i < links.length; i++) {
            links[i].children[0].onclick = function(event) {
                let hashIndex = links[i].children[0].href.indexOf("#");
                if (hashIndex !== -1) {
                    event.preventDefault();
                    let id = links[i].children[0].href.substr(hashIndex + 1, links[i].children[0].href.length - 1);
                    scrollToId(id);
                }
            }
        }
    }

    $( document ).ready(function() {
        /* document.body.scrollLeft = 0; */

        // let helpSuggest  = document.querySelector(".help-suggest");
        let videoTag = document.querySelector(".video-tag");
        // let helpNameDiv = document.querySelector(".help-name-div")
        // let helpName = document.querySelector(".help-name-div input");
        let hamburger = document.querySelector(".menu-opener");
        // let player = document.querySelector(".player");
        let hideVideoButton = document.querySelector(".hide-video-button");
        // let helpMail = document.querySelector(".help-section input[type='email']");
        // let helpLinkedin = document.querySelector(".help-section input[type='url']");
        // let helpTextarea = document.querySelector(".help-section textarea");
        // let helpButton = document.querySelector(".help-button");
        let newsletterButton = document.querySelector(".newsletter input[type='submit']");
        let newsletterInput = document.getElementById("newsletter-input");
        let newsletterInputName = document.getElementById("newsletter-name");
        let newsletterLabel = document.querySelector(".newsletter label");
        let newsletterDone = document.querySelector(".newsletter-done");
        // let helpThanksDiv = document.querySelector(".help-thanks-div");

        bindUlHrefs(document.querySelector("header ul"));
        bindUlHrefs(document.querySelector("footer ul"));

        let videos = document.querySelector(".videos-section").children;

        for (let i = 0; i < videos.length; i++) {
            videos[i].addEventListener("click", () => {
                let videoName = videos[i].getAttribute("data-backgr");
                videoTag.src = videoName;
                scrollTo(document.body);
                playVideo();

                for (let j = 0; j < videos.length; j++) {
                    videos[j].classList.remove("active");
                }
                videos[i].classList.add("active");
            });
        }
        
        /*
        let helpSuggestions = helpSuggest.children;

        for (let i = 0; i < helpSuggestions.length; i++) {
            // console.log('2 Adding event listener for', i, helpSuggestions[i], helpSuggestions[i].firstChild, helpSuggestions[i].firstChild.innerHTML);
            helpSuggestions[i].addEventListener("click", ()=> {
               //  console.log(i, 'clicked');
                *
                if (i === helpSuggestions.length - 1) {
                    console.log('clear');
                    helpName.value = "";
                    helpName.focus();
                } else {
                /
                    // console.log('set', helpSuggestions[i], helpSuggestions[i].firstChild, helpSuggestions[i].firstChild.innerHTML);
                    helpName.value = helpSuggestions[i].firstChild.innerHTML;
                //}
                showHelpSuggestions(false, 'click');
               /
                if (helpName.value === 'An Entrepreneur') {
                    document.querySelector('.help-deck-div').classList.add("show")
                } else {
                    document.querySelector('.help-deck-div').classList.remove("show")
                }
              /
            });
        }

        helpName.addEventListener("focus", () => {
            showHelpSuggestions(true, 'focus');
        });
        helpName.addEventListener("click", (e) => {
            e.preventDefault();
            showHelpSuggestions(true, 'click');
        });
        helpName.addEventListener("keydown", (e) => {
            e.preventDefault();
            showHelpSuggestions(true, 'keydown');
        });
        helpName.addEventListener("keydown", (e) => {
            e.preventDefault();
            showHelpSuggestions(true, 'keyup');
        });
        helpNameDiv.addEventListener("blur", () => {
            setTimeout(() => {
                showHelpSuggestions(false, 'focusout-delay');
            }, 300);
        });
        helpName.addEventListener("keyup", enableHelpButton);

        // helpName.addEventListener("focus", showHelpSuggestions);
        helpMail.addEventListener("keyup", enableHelpButton);
        helpTextarea.addEventListener("keyup", enableHelpButton);
        */ 
        hamburger.addEventListener("click", toggleMenu);

        newsletterInput.addEventListener("keyup", enableNewsletterButton);


        // player.addEventListener("click", playVideo);

        hideVideoButton.addEventListener("click", hideVideo);

        // window.onscroll = function() {scrollFunc()};
        // $( document.body ).scroll(scrollFunc);

        $(window).scroll(function() {
            scrollFunc();
        });

        document.addEventListener( 'wpcf7invalid', function(event) {
            // console.log(event);

            if (event.target.id == "wpcf7-f56-o1") {
                newsletterButton.disabled = true;
                newsletterButton.value = "Enter a valid e-mail";
                newsletterInput.classList.add("error");
            } /* else if (event.target.id == "wpcf7-f55-p14-o2") {

                if (helpTextarea.nextSibling != null && helpTextarea.nextSibling.nodeName == "SPAN") {
                    helpTextarea.classList.add("error");
                    helpButton.value = "Text field can't be empty";
                }

                if (helpMail.nextSibling != null && helpMail.nextSibling.nodeName == "SPAN") {
                    helpMail.classList.add("error");
                    helpButton.value = "Enter a valid e-mail";
                }

                if (helpName.nextSibling != null && helpName.nextSibling.nodeName == "SPAN") {
                    helpName.classList.add("error");
                    helpButton.value = "Name field can't be empty";
                }

                helpButton.disabled = true;
            } */
        }, false);

        document.addEventListener( 'wpcf7mailsent', function(event) {
            // console.log(event, event.detail.contactFormId, event.detail.contactFormId);

            if (event.detail.contactFormId == "56") {
                newsletterLabel.innerHTML = "";
                newsletterButton.disabled = true;
                newsletterButton.style.display = "none";
                newsletterInput.disabled = true;
                newsletterInput.style.display = "none";
                // newsletterInputName.disabled = true;
                // newsletterInputName.style.display = "none";
                newsletterDone.style.display = "inline-block";
                newsletterDone.querySelector("p").innerHTML = "Thanks for subscribing to our newsletter.";
            } /* else if (event.detail.contactFormId == "55") {
                helpButton.disabled = true;
                helpButton.style.visibility = "hidden";
                helpMail.disabled = true;
                helpMail.style.visibility = "hidden";
                helpLinkedin.disabled = true;
                helpLinkedin.style.visibility = "hidden";
                helpTextarea.disabled = true;
                helpTextarea.style.visibility = "hidden";
                helpName.disabled = true;
                helpName.style.visibility = "hidden";
                // document.querySelector('.help-deck-div').style.visibility = "hidden";
                helpThanksDiv.classList.add("show");
            } */
        }, false);
        var overlaySection = $('.top-section');
        var playerBtn = $('button.player');
        var videoSection = $('.videos-section');
        var logoDiv = $('.logo');
        var timeoutPlayer=-1;
        /*
        overlaySection.on('mousemove', function () {
            playerBtn.addClass('visibilitys');
            videoSection.addClass('visibilitys');
            logoDiv.addClass('visibilitys');
            clearTimeout(timeoutPlayer);
            timeoutPlayer = setTimeout(function () {
                playerBtn.removeClass('visibilitys');
                videoSection.removeClass('visibilitys');
                logoDiv.removeClass('visibilitys');
            }, 2000);
        });
        overlaySection.on('mouseleave', function () {
            playerBtn.removeClass('visibilitys');
            videoSection.removeClass('visibilitys');
            logoDiv.removeClass('visibilitys');
            clearTimeout(timeoutPlayer);
        });
        */
        // console.log(overlaySection);
    });
})(jQuery);

